import Vue from 'vue'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import { VueMaskDirective } from 'v-mask'

import '@/plugins'
import '@/components'

Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false

/* eslint-disable */
if ($('#app').length) {
  new Vue({
    el: '#app',
    store,
    vuetify
  })
}
/* eslint-enable */
