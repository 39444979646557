<template>
  <v-form
    @submit.prevent="handleLogin"
    ref="loginForm"
    v-model="validForm"
    lazy-validation
  >
    <v-container>
      <v-row class="justify-center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-card :loading="isLoading" class="pa-5" elevation="10" outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.username"
                    placeholder="Имя пользователя или почта"
                    v-bind="inputProps"
                    required
                    :rules="reqiuredRules"
                    class="user-form__input"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.password"
                    placeholder="Пароль"
                    :type="showPassword ? 'text' : 'password'"
                    v-bind="inputProps"
                    required
                    :rules="reqiuredRules"
                    class="user-form__input"
                  />
                </v-col>
                <v-col class="py-0">
                  <div>
                    Чтобы восстановить пароль обратитесь к организаторам<br /><a
                      href="mailto:fb.rsue@mail.ru"
                      >fb.rsue@mail.ru</a
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                :disabled="isLoading"
                type="submit"
                color="deep-purple lighten-2 text-white"
              >
                Войти
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  export default {
    name: 'LoginForm',
    data: () => ({
      inputProps: {
        dense: true,
        outlined: true,
        color: '#B19AE1',
        hideDetails: true
      },
      user: {
        username: '',
        password: ''
      },
      isLoading: false,
      showPassword: false,
      validForm: true,
      reqiuredRules: [(val) => !!val || 'Поле обязательное для заполнения']
    }),
    methods: {
      async handleLogin() {
        if (this.$refs.loginForm.validate()) {
          try {
            this.isLoading = true
            const user = new FormData()
            user.append('username', this.user.username)
            user.append('password', this.user.password)
            const { data: succesUrl } = await axios.post(
              window.location.href,
              user
            )
            await Swal.fire({
              icon: 'success',
              title: 'Успешно',
              showConfirmButton: false,
              timer: 1500
            })
            window.location.replace(succesUrl)
          } catch (error) {
            console.error(error)
            Swal.fire({
              icon: 'error',
              title: 'Возникла ошибка',
              text: 'Проверьте правильность введенных данных',
              showConfirmButton: false,
              timer: 1500
            })
          } finally {
            this.isLoading = false
          }
        }
      }
    }
  }
</script>
