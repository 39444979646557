<template>
  <div
    class="expert-validation__document-preview overflow-hidden elevation-5"
    :style="`width: ${width}px; height: ${height}px`"
    @click="open"
  >
    <img v-if="!file" :src="alt" />
    <img v-else-if="file.endsWith('.pdf')" src="/static/images/pdf.svg" />
    <img
      class="img-fluid"
      v-else-if="
        file.endsWith('.jpeg') ||
        file.endsWith('.jpg') ||
        file.endsWith('.heic') ||
        file.endsWith('.png')
      "
      :src="file"
      style="object-fit: cover;"
    />
  </div>
</template>

<script>
  export default {
    props: {
      width: {
        type: Number,
        default: 305
      },
      height: {
        type: Number,
        default: 413
      },
      file: {
        type: String,
        required: false
      },
      alt: {
        type: String,
        required: true
      }
    },
    methods: {
      open() {
        this.file && window.open(this.file, '_blank')
      }
    }
  }
</script>
