<template>
  <div>
    <multiselect
      track-by="name"
      label="name"
      v-model="value"
      :options="options"
      multiple
      placeholder="Выберите предметы"
      select-label="Нажмите, чтобы выбрать"
      selected-label="1"
      deselect-label="-"
      @input="onInput"
    >
      <template slot="selection" slot-scope="{ values }">
        <span v-if="values.length">{{ values.length }} Выбрано предметов</span>
      </template>
    </multiselect>
    <input
      type="hidden"
      name="subject"
      v-for="item in results"
      :key="item"
      :value="item"
    />
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'multi-select',
    components: { Multiselect },
    props: { options: Array },
    data() {
      return {
        value: null,
        results: []
      }
    },
    methods: {
      onInput(name, id) {
        console.log(name)
        // this.$refs.inputSubject.value = [2, 3]
        this.results = name.map(function callback(currentValue) {
          return currentValue.value
        })
        this.$refs.inputSubject.value = name.map(function callback(
          currentValue
        ) {
          return currentValue.value
        })
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style></style>
