<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="1050px">
    <v-card :loading="loading" v-if="value">
      <v-card-title>
        <v-btn
          @click="$emit('previous', $event)"
          class="text-body-1"
          elevation="0"
          color="accent"
          tile
          :disabled="!hasPrevious"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          @click="$emit('next', $event)"
          class="text-body-1"
          elevation="0"
          color="primary"
          tile
          :disabled="!hasNext"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="justify-content-between">
            <FilePreview
              :file="participant.agreement"
              alt="/static/images/not_agreement.svg"
            />
            <FilePreview
              :file="participant.agreement_to_distribution"
              alt="/static/images/not_distribution.svg"
            />
            <FilePreview
              :file="participant.education"
              alt="/static/images/not_education.svg"
            />
          </v-row>
          <v-row>
            <v-col class="px-0" cols="6">
              <v-btn
                class="text-body-1"
                :class="
                  participant.is_incorrectly_filled
                    ? 'text-white'
                    : 'text-black'
                "
                elevation="0"
                :color="participant.is_incorrectly_filled ? 'red' : 'gray'"
                tile
                block
                @click="
                  $emit('update-errors', {
                    val: !participant.is_incorrectly_filled,
                    item: participant
                  })
                "
              >
                <v-icon>mdi-alert</v-icon>
                ЕСТЬ ОШИБКИ
              </v-btn>
            </v-col>
            <v-col class="px-0" cols="6">
              <v-btn
                class="text-body-1"
                :class="participant.is_verified ? 'text-white' : 'text-black'"
                elevation="0"
                :color="participant.is_verified ? 'green' : 'gray'"
                tile
                block
                @click="
                  $emit('update-is-verified', {
                    val: !participant.is_verified,
                    item: participant
                  })
                "
              >
                <v-icon>mdi-check</v-icon>
                НЕТ ОШИБОК
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <div class="expert-dialog__participant--blue">
                <p v-if="participant.role == 2">
                  Студент
                </p>
                <p v-else>
                  Школьник
                </p>
                <p class="expert-dialog__participant-full-name">
                  <span style="font-weight: 700;"
                    >{{ participant.user.first_name }}
                    {{ participant.user.last_name }}</span
                  ><br />
                  {{ participant.user.patronymic }}
                </p>
                <p class="expert-dialog__participant-dob">
                  {{ participant.date_of_birth }}
                </p>
              </div>
              <div class="expert-dialog__participant--blue mt-1">
                <p class="expert-dialog__participant-locality">
                  {{ participant.locality_type.short_name }}
                  {{ participant.locality }}
                </p>
                <p class="expert-dialog__participant-region">
                  {{ participant.region.name }}
                </p>
                <p
                  v-if="participant.district"
                  class="expert-dialog__participant-region"
                >
                  {{ participant.district.name }}
                </p>
                <p class="expert-dialog__participant-region">
                  {{ participant.county.name }}
                </p>
              </div>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col cols="4" class="expert-dialog__participant-information">
                  <p class="expert-dialog__participant-information--title">
                    Телефон
                  </p>
                  <a
                    :href="`tel:${participant.phone}`"
                    class="expert-dialog__participant-information--content"
                  >
                    {{ participant.phone }}
                  </a>
                </v-col>
                <v-col cols="8" class="expert-dialog__participant-information">
                  <p class="expert-dialog__participant-information--title">
                    Электронная почта
                  </p>
                  <a
                    :href="`mailto:${participant.user.email}`"
                    class="expert-dialog__participant-information--content"
                  >
                    {{ participant.user.email }}
                  </a>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col class="expert-dialog__participant-information">
                  <p class="expert-dialog__participant-information--title">
                    Учебное заведение
                  </p>
                  <p
                    v-if="participant.role == 2"
                    class="expert-dialog__participant-information--content"
                  >
                    {{ participant.place_study }}
                  </p>
                  <p
                    v-else
                    class="expert-dialog__participant-information--content"
                  >
                    {{ participant.school }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="expert-dialog__participant-information">
                  <p
                    v-if="participant.role == 2"
                    class="expert-dialog__participant-information--title"
                  >
                    Курс
                  </p>
                  <p
                    v-else
                    class="expert-dialog__participant-information--title"
                  >
                    Класс
                  </p>
                  <p class="expert-dialog__participant-information--content">
                    {{
                      participant.role == 2
                        ? participant.course
                        : participant.teaching_class
                    }}
                  </p>
                </v-col>
                <v-col
                  cols="8"
                  v-if="participant.role == 2"
                  class="expert-dialog__participant-information"
                >
                  <p class="expert-dialog__participant-information--title">
                    Факультет
                  </p>
                  <p class="expert-dialog__participant-information--content">
                    {{ participant.faculty }}
                  </p>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col class="expert-dialog__participant-information">
                  <p class="expert-dialog__participant-information--title">
                    Предметы
                  </p>
                  <p
                    v-for="subject in participant.subjects"
                    :key="subject.id"
                    class="expert-dialog__participant-information--content mb-1"
                  >
                    {{ subject.name }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import FilePreview from './FilePreview.vue'
  export default {
    inheritAttrs: false,
    components: {
      FilePreview
    },
    props: {
      participant: Object,
      value: Boolean,
      loading: Boolean,
      hasNext: Boolean,
      hasPrevious: Boolean
    }
  }
</script>
