import Vue from 'vue'
import App from './layout/App'
import Example from './ui/Example'
import ExpertValidation from './ui/ExpertValidation'
import LoginForm from './ui/LoginForm'
import PersonalArea from './ui/PersonalArea'
import SelectVue from './ui/SelectVue'
import UserForm from './ui/UserForm'
[
  App,
  SelectVue,
  UserForm,
  LoginForm,
  PersonalArea,
  ExpertValidation,
  {
    name: 'example',
    ...Example
  }

  // if not component name
  // { name: 'component-name', ...Component }
].forEach(Component => {
  if (!Component.name) {
    throw new Error(`Not component name: ${Component}`)
  }

  Vue.component(Component.name, Component)
})
