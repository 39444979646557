<template>
  <v-form
    @submit.prevent="handleUser"
    ref="userForm"
    v-model="validForm"
    lazy-validation
  >
    <v-container>
      <!-- <v-alert class="my-2" v-if="user.is_verified" type="success"
        >Ваша заявка подтверждена модератором.</v-alert
      >
      <v-alert class="my-2" v-else-if="user.is_incorrectly_filled" type="error"
        >Ваша заявка имеет ошибки! Проверьте правильность заполнения данных и
        документы.</v-alert
      >
      <v-alert class="my-2" v-else type="info"
        >Ваша заявка ожидает проверки.</v-alert
      > -->
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="3"
          class="d-flex align-items-center"
        >
          <p class="user-form__label">
            ФИО
          </p>
        </v-col>
        <v-col class="d-md-flex" cols="12" sm="12" md="6" lg="6">
          <v-text-field
            v-model="participant.last_name"
            placeholder="Фамилия"
            v-bind="inputProps"
            required
            :rules="reqiuredRules"
            class="user-form__input"
          />
          <v-text-field
            v-model="participant.first_name"
            placeholder="Имя"
            v-bind="inputProps"
            required
            :rules="reqiuredRules"
            class="user-form__input"
          />
          <v-text-field
            v-model="participant.patronymic"
            placeholder="Отчество"
            v-bind="inputProps"
            class="user-form__input"
          />
        </v-col>
      </v-row>
      <div class="mb-5 pb-3">
        <transition name="slide-x-transition">
          <div v-if="participant.role === 1">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                class="d-flex align-items-center"
              >
                <p class="user-form__label">
                  Школа/Лицей
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="participant.school"
                  placeholder="Школа/Лицей"
                  v-bind="inputProps"
                  required
                  :rules="reqiuredRules"
                  class="user-form__input"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                class="d-flex align-items-center"
              >
                <p class="user-form__label">
                  Класс
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="participant.teaching_class"
                  placeholder="Класс"
                  v-bind="inputProps"
                  required
                  :rules="reqiuredRules"
                  class="user-form__input"
                />
              </v-col>
            </v-row>
          </div>
        </transition>
        <transition name="slide-x-transition">
          <div v-if="participant.role === 2">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                class="d-flex align-items-center"
              >
                <p class="user-form__label">
                  ВУЗ
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="participant.place_study"
                  placeholder="ВУЗ"
                  v-bind="inputProps"
                  required
                  :rules="reqiuredRules"
                  class="user-form__input"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                class="d-flex align-items-center"
              >
                <p class="user-form__label">
                  Курс
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="participant.course"
                  placeholder="Курс"
                  v-bind="inputProps"
                  required
                  :rules="reqiuredRules"
                  class="user-form__input"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                class="d-flex align-items-center"
              >
                <p class="user-form__label">
                  Факультет
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="participant.faculty"
                  placeholder="Факультет"
                  v-bind="inputProps"
                  required
                  :rules="reqiuredRules"
                  class="user-form__input"
                />
              </v-col>
            </v-row>
            <v-row class="mb-5 pb-3">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                class="d-flex align-items-center"
              >
                <p class="user-form__label">
                  Уровень образования
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-select
                  :items="educationLevels"
                  item-text="value"
                  item-value="key"
                  v-model="participant.education_level"
                  placeholder="Уровень образования"
                  v-bind="inputProps"
                  required
                  :rules="reqiuredRules"
                  class="user-form__input"
                />
              </v-col>
            </v-row>
          </div>
        </transition>
      </div>
      <div class="mb-5 pb-3">
        <transition name="slide-x-transition">
          <v-row v-if="participant.county">
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              class="d-flex align-items-center"
            >
              <p class="user-form__label">
                Регион
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-autocomplete
                v-model="participant.region"
                :items="filteredRegions"
                item-value="id"
                item-text="name"
                placeholder="Регион"
                v-bind="inputProps"
                required
                :rules="reqiuredRules"
                class="user-form__input"
              />
            </v-col>
          </v-row>
        </transition>
        <transition name="slide-x-transition">
          <v-row v-if="participant.region">
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              class="d-flex align-items-center"
            >
              <p class="user-form__label">
                Район
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-autocomplete
                v-model="participant.district"
                :items="filteredDistricts"
                item-value="id"
                item-text="name"
                placeholder="Район"
                v-bind="inputProps"
                append-outer-icon="mdi mdi-close"
                @click:append-outer="participant.district = ''"
                class="user-form__input"
              />
            </v-col>
          </v-row>
        </transition>
        <transition name="slide-x-transition">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              class="d-flex align-items-center"
            >
              <p class="user-form__label">
                Населенный пункт
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-autocomplete
                v-model="participant.locality_type"
                placeholder="Тип населенного пункта"
                :items="address.locality_types"
                item-text="name"
                item-value="id"
                required
                v-bind="inputProps"
                :rules="reqiuredRules"
                class="user-form__input"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Ничего не найдено
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-text-field
                v-model="participant.locality"
                placeholder="Населенный пункт"
                v-bind="inputProps"
                required
                :rules="reqiuredRules"
                class="user-form__input"
              />
            </v-col>
          </v-row>
        </transition>
      </div>

      <transition name="slide-x-transition">
        <v-row v-if="filteredSubjects.length > 0" class="mb-5 pb-3">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="3"
            class="d-flex align-items-center"
          >
            <p class="user-form__label">
              Предметы
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-select
              v-model="participant.subjects"
              :items="filteredSubjects"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              placeholder="Предметы"
              multiple
              v-bind="inputProps"
              required
              :rules="reqiuredRules"
              class="user-form__input"
            />
          </v-col>
        </v-row>
      </transition>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="3"
          class="d-flex align-items-center"
        >
          <p class="user-form__label">
            Контактный телефон
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-text-field
            v-model="participant.phone"
            placeholder="Контактный телефон"
            v-mask="'+7 (###) ##-###-##'"
            v-bind="inputProps"
            required
            :rules="reqiuredRules"
            class="user-form__input"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="3"
          class="d-flex align-items-center"
        >
          <p class="user-form__label">
            Дата рождения
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-menu
            v-model="dateOfBirthPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateOfBirthFormatted"
                placeholder="Дата рождения"
                prepend-inner-icon="mdi-calendar"
                readonly
                required
                v-bind="{ ...inputProps, ...attrs }"
                v-on="on"
                :rules="reqiuredRules"
                class="user-form__input"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="participant.date_of_birth"
              no-title
              locale="ru"
              :active-picker.sync="activePicker"
              :min="
                new Date(
                  1990,
                  new Date().getMonth(),
                  new Date().getDay()
                ).toISOString()
              "
              :max="
                new Date(
                  new Date().getFullYear() - 14,
                  new Date().getMonth(),
                  new Date().getDay()
                ).toISOString()
              "
              @input="dateOfBirthPicker = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mb-5 pb-3">
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="3"
          class="d-flex align-items-center"
        >
          <p class="user-form__label">
            Электронная почта
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-text-field
            v-model="participant.email"
            placeholder="Электронная почта"
            v-bind="inputProps"
            required
            :rules="emailRules"
            class="user-form__input"
          />
        </v-col>
      </v-row>
      <v-row class="mb-5 pb-3">
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="3"
          class="d-flex align-items-center"
        >
          <div>
            <p class="user-form__label">
              Согласие на обработку персональных данных
            </p>
            <p class="m-0">
              <a :href="agreement" download>Шаблон заявления</a>
            </p>
            <p class="m-0">
              <a
                v-if="this.agreementPreview"
                :href="this.agreementPreview"
                download
                >Загруженный файл</a
              >
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-file-input
            v-model="participant.agreement"
            placeholder="Форматы: pdf, jpeg, jpg, png, heic"
            hint="Можно добавить позже в личном кабинете"
            :hide-details="false"
            persistent-hint
            v-bind="inputProps"
            prepend-icon=""
            class="user-form__input"
            :accept="fileAccept.join(', ')"
            :rules="[fileTypeRules]"
          />
        </v-col>
      </v-row>
      <v-row class="mb-5 pb-3">
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="3"
          class="d-flex align-items-center"
        >
          <div>
            <p class="user-form__label">
              Согласие на распространение персональных данных
            </p>
            <p class="m-0">
              <a :href="agreementToDistribution" download>Шаблон заявления</a>
            </p>
            <p class="m-0">
              <a
                v-if="this.agreementToDistributionPreview"
                :href="this.agreementToDistributionPreview"
                download
                >Загруженный файл</a
              >
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-file-input
            v-model="participant.agreement_to_distribution"
            placeholder="Форматы: pdf, jpeg, jpg, png, heic"
            hint="Можно добавить позже в личном кабинете"
            :hide-details="false"
            persistent-hint
            v-bind="inputProps"
            prepend-icon=""
            class="user-form__input"
            :accept="fileAccept.join(', ')"
            :rules="[fileTypeRules]"
          />
        </v-col>
      </v-row>
      <v-row class="mb-5 pb-3">
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="3"
          class="d-flex align-items-center"
        >
          <div>
            <p class="user-form__label">
              Электронная копия документа, подтверждающая обучение участника
            </p>
            <p class="m-0">
              <a
                v-if="this.educationPreview"
                :href="this.educationPreview"
                download
                >Загруженный файл</a
              >
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-file-input
            v-model="participant.education"
            placeholder="Форматы: pdf, jpeg, jpg, png, heic"
            hint="Можно добавить позже в личном кабинете"
            :hide-details="false"
            persistent-hint
            v-bind="inputProps"
            prepend-icon=""
            class="user-form__input"
            :accept="fileAccept.join(', ')"
            :rules="[fileTypeRules]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          offset-lg="3"
          cols="12"
          sm="12"
          md="12"
          lg="5"
          class="d-flex justify-content-center"
        >
          <v-btn class="user-form__button" elevation="2" rounded type="submit"
            >Сохранить изменения</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  export default {
    name: 'PersonalArea',
    props: {
      address: Object,
      roles: Array,
      educationLevels: Array,
      subjects: Array,
      agreement: String,
      agreementToDistribution: String,
      user: Object,
      mediaUrl: String
    },
    data: () => ({
      inputProps: {
        dense: true,
        outlined: true,
        color: '#B19AE1',
        hideDetails: true
      },
      participant: {
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        role: '',
        phone: '',
        county: '',
        region: '',
        district: '',
        locality: '',
        locality_type: '',
        date_of_birth: '',
        course: '',
        faculty: '',
        subjects: [],
        agreement: null,
        agreement_to_distribution: null,
        education: null
      },
      agreementPreview: null,
      agreementToDistributionPreview: null,
      educationPreview: null,
      activePicker: null,
      isLoading: false,
      dateOfBirthPicker: false,
      validForm: true,
      fileAccept: [
        'application/pdf',
        'image/heic',
        'image/png',
        'image/jpeg',
        'image/jpg'
      ],
      emailRules: [
        v => /.+@.+\..+/.test(v) || 'Введите корректную электронную почту',
        val => !!val || 'Введите корректную электронную почту'
      ],
      reqiuredRules: [val => !!val || 'Поле обязательное для заполнения']
    }),
    mounted() {
      this.agreementPreview = this.user.agreement
      this.agreementToDistributionPreview = this.user.agreement_to_distribution
      this.educationPreview = this.user.education
      delete this.user.agreement
      delete this.user.agreement_to_distribution
      delete this.user.education
      Object.assign(this.participant, this.user)
    },
    watch: {
      dateOfBirthPicker(val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      }
    },
    computed: {
      filteredRegions() {
        return this.address.regions.filter(
          r => r.county_id === this.participant.county
        )
      },
      filteredDistricts() {
        const districts = this.address.districts.filter(
          d => d.region_id === this.participant.region
        )
        districts.push({ id: '', name: '------' })
        return districts
      },
      filteredSubjects() {
        return this.subjects.filter(
          s =>
            s.county === this.participant.county &&
            s.type === this.participant.role
        )
      },
      dateOfBirthFormatted() {
        return this.formatDate(this.participant.date_of_birth)
      }
    },
    methods: {
      fileTypeRules(file) {
        return (
          !file ||
          this.fileAccept.indexOf(file.type) > -1 ||
          'Неверный формат файла!'
        )
      },
      formatDate(date) {
        if (!date) return null

        return new Date(date).toLocaleDateString('ru', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })
      },
      getFormData(data) {
        const formData = new FormData()
        for (const [key, value] of Object.entries(data)) {
          if (typeof value === 'object' && !(value instanceof File)) {
            formData.append(key, JSON.stringify(value))
          } else {
            formData.append(key, value)
          }
        }
        return formData
      },
      async handleUser() {
        if (this.$refs.userForm.validate()) {
          const participant = this.getFormData(this.participant)
          try {
            this.isLoading = true
            await axios.post(window.location.href, participant)
            await Swal.fire({
              icon: 'success',
              title: 'Успешно',
              showConfirmButton: false,
              timer: 1500
            })
          } catch (error) {
            console.error(error)
            Swal.fire({
              icon: 'error',
              title: 'Возникла ошибка',
              showConfirmButton: false,
              timer: 1500
            })
          } finally {
            this.isLoading = false
          }
        }
      }
    }
  }
</script>
