<template>
  <div class="container-fluid expert-validation">
    <v-row class="justify-end">
      <v-btn
        elevation="0"
        class="expert-validation__top-links"
        color="deep-purple"
        tile
        href="/participants/logout/"
        >Выйти из личного кабитнета</v-btn
      >
      <v-btn
        elevation="0"
        class="expert-validation__top-links"
        color="primary text--white"
        tile
        @click="resetFilters"
        >Сбросить фильтры</v-btn
      >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            class="expert-validation__top-links"
            color="deep-purple"
            tile
            v-bind="attrs"
            v-on="on"
            >Сформировать отчет</v-btn
          >
        </template>
        <v-list>
          <v-list-item download :href="uploads[1]">
            <v-list-item-title>Школьники</v-list-item-title>
          </v-list-item>
          <v-list-item download :href="uploads[2]">
            <v-list-item-title>Студенты</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        elevation="0"
        class="expert-validation__top-links"
        color="primary text--white"
        tile
        href="/dj-admin/"
        >Панель администратора</v-btn
      >
    </v-row>
    <v-row class="px-3">
      <v-col cols="12">
        <h1 class="expert-validation__title">
          Валидация участников
        </h1>
      </v-col>
      <v-col cols="12">
        <v-btn-toggle
          tile
          v-model="selectedCounty"
          color="deep-purple"
          @change=";(selectedSubjects = []), (selectedRegions = [])"
          class="expert-validation__main-filter mr-5"
        >
          <v-btn v-for="county in counties" :key="county.id" :value="county.id">
            {{ county.short_name }}
          </v-btn>
        </v-btn-toggle>
        <v-btn-toggle
          tile
          v-model="selectedRole"
          color="deep-purple "
          @change="
            ;(selectedSubjects = []),
              (selectedClasses = []),
              (selectedStudyPlace = [])
          "
          class="expert-validation__main-filter ml-5"
        >
          <v-btn v-for="role in roles" :key="role.key" :value="role.key">
            {{ role.value }}
          </v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col class="p-0" cols="12">
        <v-divider />
      </v-col>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="expert-validation__filter-accordeon-header"
          >
            Фильтры
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col class="d-flex justify-content-center p-0" cols="12">
              <v-btn
                @click="selectedRegions = regions.map(r => r.id)"
                rounded
                small
                class="mr-5"
                text
              >
                выбрать все регионы
              </v-btn>
              <v-btn @click="selectedRegions = []" rounded small text>
                сбросить выбор
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn-toggle
                v-model="selectedRegions"
                multiple
                color="white"
                class="expert__region-button-group"
              >
                <v-btn
                  v-for="region in filteredRegions"
                  :key="region.id"
                  :value="region.id"
                  outlined
                  class="expert__region-button"
                >
                  {{ region.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="selectedStudyPlace"
                  :items="filteredStudyPlaces"
                  label="Учебное заведение"
                  outlined
                  class="user-form__input"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  color="#B19AE1"
                  :disabled="!this.selectedRole"
                  persistent-hint
                  :hint="
                    !selectedRole
                      ? 'Выберите студентов или школьников для фильтра по учебному заведению'
                      : ''
                  "
                />
              </v-col>
              <v-col cols="6" v-if="selectedRole">
                <v-autocomplete
                  v-model="selectedClasses"
                  :items="filteredClasses"
                  item-value="id"
                  item-text="name"
                  class="user-form__input"
                  :label="
                    selectedRole === 1
                      ? 'Класс'
                      : selectedRole === 2
                      ? 'Факультет'
                      : ''
                  "
                  outlined
                  multiple
                  chips
                  deletable-chips
                  clearable
                  color="#B19AE1"
                />
              </v-col>
              <v-col cols="6" v-else>
                <v-autocomplete
                  disabled
                  outlined
                  multiple
                  class="user-form__input"
                  chips
                  deletable-chips
                  color="#B19AE1"
                  persistent-hint
                  hint="Выберите роль участника"
                />
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="selectedSubjects"
                  :items="filteredSubjects"
                  item-value="id"
                  item-text="name"
                  label="Предметы"
                  color="#B19AE1"
                  class="user-form__input"
                  outlined
                  multiple
                  dense
                  clearable
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-col class="p-0" cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12" class="p-0">
        <v-row>
          <v-col cols="12">
            <v-row class="align-items-end">
              <v-col class="d-flex justify-content-center">
                <v-text-field
                  v-model="searchByFullName"
                  append-icon="mdi-magnify"
                  label="Поиск по ФИО"
                  outlined
                  clearable
                  class="user-form__input"
                  color="#B19AE1"
                  hide-details
                />
              </v-col>
              <v-col class="d-flex flex-column align-items-center">
                <p class="expert-validation__filter-label m-0">Ошибки</p>
                <v-btn-toggle
                  v-model="hasErrors"
                  rounded
                  mandatory
                  class="expert-validation__filter-input"
                >
                  <v-btn :value="null">
                    Все
                  </v-btn>
                  <v-btn :value="true">
                    Присутствуют
                  </v-btn>
                  <v-btn :value="false">
                    Отсутствуют
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col class="d-flex flex-column align-items-center">
                <p class="expert-validation__filter-label m-0">Валидация</p>
                <v-btn-toggle
                  v-model="isVerified"
                  rounded
                  mandatory
                  class="expert-validation__filter-input"
                >
                  <v-btn :value="null">
                    Все
                  </v-btn>
                  <v-btn :value="true">
                    Прошел
                  </v-btn>
                  <v-btn :value="false">
                    Не прошел
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="p-0" cols="12">
            <v-divider />
          </v-col>
          <v-col class="expert-validation__table-container p-1" cols="12">
            <v-data-table
              class="expert-validation__table"
              :headers="tableHeaders"
              :items="filteredParticipants"
              :search="searchByFullName"
              :custom-filter="filterByFullName"
              :page.sync="tablePage"
              :items-per-page="itemsPerPage"
              hide-default-footer
              :item-class="colorCell"
              @page-count="tablePageCount = $event"
              fixed-header
            >
              <template v-slot:[`item.full_name`]="{ item }">
                <v-btn
                  class="p-0"
                  text
                  plain
                  :ripple="false"
                  rounded
                  color="primary"
                  @click="openDialog(item)"
                >
                  {{ getFullName(item) }}
                </v-btn>
              </template>
              <template v-slot:[`item.role`]="{ item }">
                {{ roles.filter(r => r.key === item.role)[0].value }}
              </template>
              <template v-slot:[`item.study_place`]="{ item }">
                <p class="m-0 text-body-1 primary--text">
                  {{ item.school || item.place_study }}
                </p>
                <p class="m-0 text-caption">
                  {{
                    [
                      item.teaching_class || item.faculty,
                      item.course || ''
                    ].join(' ')
                  }}
                </p>
              </template>
              <template v-slot:[`item.county`]="{ item }">
                {{ item.county.short_name }}
              </template>
              <template v-slot:[`item.adress`]="{ item }">
                <p class="m-0 text-body-1 primary--text">
                  {{ [item.locality_type.short_name, item.locality].join(' ') }}
                </p>
                <p class="m-0 text-caption">{{ item.region.name }}</p>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                {{ item.user.email }}
              </template>
              <template v-slot:[`item.agreement`]="{ item }">
                <v-btn
                  v-if="item.agreement"
                  :href="item.agreement"
                  target="_blank"
                  icon
                >
                  <img
                    v-if="item.agreement.endsWith('.pdf')"
                    src="/static/icons/file_PDF.svg"
                  />
                  <img v-else src="/static/icons/file_JPG.svg" />
                </v-btn>
                <p v-else class="expert-validation__table-no-file">
                  ФАЙЛ<br />ОТСУТСТВУЕТ
                </p>
              </template>
              <template v-slot:[`item.agreement_to_distribution`]="{ item }">
                <v-btn
                  v-if="item.agreement_to_distribution"
                  :href="item.agreement_to_distribution"
                  target="_blank"
                  icon
                >
                  <img
                    v-if="item.agreement_to_distribution.endsWith('.pdf')"
                    src="/static/icons/file_PDF.svg"
                  />
                  <img v-else src="/static/icons/file_JPG.svg" />
                </v-btn>
                <p v-else class="expert-validation__table-no-file">
                  ФАЙЛ<br />ОТСУТСТВУЕТ
                </p>
              </template>
              <template v-slot:[`item.education`]="{ item }">
                <v-btn
                  v-if="item.education"
                  :href="item.education"
                  target="_blank"
                  icon
                >
                  <img
                    v-if="item.education.endsWith('.pdf')"
                    src="/static/icons/file_PDF.svg"
                  />
                  <img v-else src="/static/icons/file_JPG.svg" />
                </v-btn>
                <p v-else class="expert-validation__table-no-file">
                  ФАЙЛ<br />ОТСУТСТВУЕТ
                </p>
              </template>
              <template v-slot:[`item.is_incorrectly_filled`]="{ item }">
                <v-simple-checkbox
                  @input="handleHasErrors($event, item)"
                  :color="item.is_incorrectly_filled ? 'red' : ''"
                  :value="item.is_incorrectly_filled"
                />
              </template>
              <template v-slot:[`item.is_verified`]="{ item }">
                <v-simple-checkbox
                  @input="handleIsVerified($event, item)"
                  :color="item.is_verified ? 'green' : ''"
                  :value="item.is_verified"
                />
              </template>
            </v-data-table>
            <div class="expert-validation__table-footer text-center pt-2">
              <v-pagination
                v-model="tablePage"
                :length="tablePageCount"
                :total-visible="7"
                color="#1DBFFF"
                prev-icon="mdi-arrow-left"
                next-icon="mdi-arrow-right"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <expert-validation-dialog
      v-model="participantDialog"
      :loading="isLoading"
      @close="participantDialog = false"
      @update-is-verified="handleIsVerified($event.val, $event.item)"
      @update-errors="handleHasErrors($event.val, $event.item)"
      :hasPrevious="
        participantsList && participantsList.indexOf(selectedParticipant) > 0
      "
      :hasNext="
        participantsList &&
          participantsList.indexOf(selectedParticipant) <
            participantsList.length - 1
      "
      @next="
        selectedParticipant =
          participantsList[participantsList.indexOf(selectedParticipant) + 1]
      "
      @previous="
        selectedParticipant =
          participantsList[participantsList.indexOf(selectedParticipant) - 1]
      "
      :participant="selectedParticipant"
    />
  </div>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import Swal from 'sweetalert2'
  import ExpertValidationDialog from './ExpertValidationDialog.vue'
  export default {
    name: 'ExpertValidation',
    components: {
      ExpertValidationDialog
    },
    props: {
      participants: Array,
      counties: Array,
      subjects: Array,
      regions: Array,
      roles: Array,
      uploads: Object
    },
    data: () => ({
      isLoading: false,
      participantDialog: false,
      selectedParticipant: null,
      participantsList: null,
      tablePage: 1,
      tablePageCount: 0,
      itemsPerPage: 6,
      selectedCounty: null,
      selectedRole: null,
      selectedStudyPlace: [],
      selectedRegions: [],
      selectedSubjects: [],
      selectedClasses: [],
      searchByFullName: '',
      isVerified: null,
      hasErrors: null
    }),
    mounted() {
      this.participantsList = this.participants
    },
    computed: {
      filteredParticipants() {
        if (!this.participantsList) return []
        return this.participantsList.filter(
          p =>
            this.checkCondition(p.county.id, this.selectedCounty) &&
            this.checkCondition(p.role, this.selectedRole) &&
            this.checkCondition(p.region.id, this.selectedRegions) &&
            this.checkCondition(
              p.school || p.place_study,
              this.selectedStudyPlace
            ) &&
            this.checkCondition(
              p.teaching_class || p.faculty,
              this.selectedClasses
            ) &&
            this.checkCondition(
              p.subjects.map(s => s.id),
              this.selectedSubjects
            ) &&
            this.checkBooleanCondition(
              this.hasErrors,
              p.is_incorrectly_filled
            ) &&
            this.checkBooleanCondition(this.isVerified, p.is_verified)
        )
      },
      filteredRegions() {
        if (!this.selectedCounty) return this.regions
        return this.regions.filter(r => r.county_id === this.selectedCounty)
      },
      filteredStudyPlaces() {
        if (!this.participantsList) return []
        return [
          ...new Set(
            this.participantsList.map(p => {
              if (this.selectedRole === 1) {
                return p.school
              }
              if (this.selectedRole === 2) {
                return p.place_study
              }
              return []
            })
          )
        ]
      },
      filteredClasses() {
        if (!this.participantsList) return []
        return [
          ...new Set(
            this.participantsList.map(p => {
              if (this.selectedRole === 1) {
                return p.teaching_class
              }
              if (this.selectedRole === 2) {
                return p.faculty
              }
              return []
            })
          )
        ]
      },
      filteredSubjects() {
        return this.subjects.filter(
          s =>
            this.checkCondition(s.county_id, this.selectedCounty) &&
            this.checkCondition(s.type, this.selectedRole)
        )
      },
      tableHeaders() {
        const fioHeader = {
          text: 'ФИО',
          value: 'full_name',
          align: 'start',
          width: 200
        }
        const roleHeader = {
          text: 'Роль',
          value: 'role',
          align: 'start',
          filterable: false,
          sortable: false
        }
        const studyPlaceHeader = {
          text: 'Учебное заведение',
          value: 'study_place',
          align: 'start',
          filterable: false
        }
        const countyHeader = {
          text: 'Округ',
          value: 'county',
          align: 'start',
          filterable: false,
          sortable: false
        }
        const addressHeader = {
          text: 'Адрес',
          value: 'adress',
          align: 'start',
          width: 250,
          filterable: false,
          sortable: false
        }
        const phoneHeader = {
          text: 'Телефон',
          value: 'phone',
          align: 'start',
          width: 170,
          filterable: false,
          sortable: false
        }
        const emailHeader = {
          text: 'E-mail',
          value: 'email',
          align: 'start',
          width: 170,
          filterable: false,
          sortable: false
        }
        const dateOfBirthHeader = {
          text: 'Дата рождения',
          value: 'date_of_birth',
          align: 'center',
          filterable: false
        }
        const agreementHeader = {
          text: 'Согласие на обработку',
          value: 'agreement',
          align: 'center',
          filterable: false,
          sortable: false
        }
        const agreementToDistributionHeader = {
          text: 'Согласие на распространение',
          value: 'agreement_to_distribution',
          align: 'center',
          filterable: false,
          sortable: false
        }
        const educationHeader = {
          text: 'Файл об образовании',
          value: 'education',
          align: 'center',
          filterable: false,
          sortable: false
        }
        const errorsHeader = {
          text: 'Ошибки',
          value: 'is_incorrectly_filled',
          align: 'center',
          filterable: false
        }
        const validationHeader = {
          text: 'Проверенный',
          value: 'is_verified',
          align: 'center',
          filterable: false
        }

        const headers = []
        headers.push(fioHeader)
        !this.selectedRole && headers.push(roleHeader)
        headers.push(studyPlaceHeader)
        !this.selectedCounty && headers.push(countyHeader)
        headers.push(
          addressHeader,
          phoneHeader,
          emailHeader,
          dateOfBirthHeader,
          agreementHeader,
          agreementToDistributionHeader,
          educationHeader
        )
        this.hasErrors === null && headers.push(errorsHeader)
        this.isVerified === null && headers.push(validationHeader)
        return headers
      }
    },
    methods: {
      colorCell(item) {
        return `expert-validation__table-cell ${
          item.is_verified
            ? 'expert-validation__table--green-cell'
            : 'expert-validation__table--red-cell'
        }`
      },
      getFullName(item) {
        return [
          item.user.last_name,
          item.user.first_name,
          item.user.patronymic
        ].join(' ')
      },
      checkBooleanCondition(val, param) {
        return val === null || param === val
      },
      checkCondition(val, param) {
        if (typeof param === 'number') {
          return !String(param) || val === param
        } else if (Array.isArray(param) && Array.isArray(val)) {
          return !param.length > 0 || _.xor(param, val).length === 0
        } else if (Array.isArray(param)) {
          return !param.length > 0 || _.includes(param, val)
        } else {
          return !param || val === param
        }
      },
      filterByFullName(value, search, item) {
        const fullName = this.getFullName(item)
        return (
          fullName != null &&
          search != null &&
          typeof fullName === 'string' &&
          fullName
            .toString()
            .toLocaleUpperCase()
            .indexOf(search.toString().toLocaleUpperCase()) !== -1
        )
      },
      resetFilters() {
        this.selectedCounty = null
        this.selectedRole = null
        this.selectedStudyPlace = []
        this.selectedRegions = []
        this.selectedSubjects = []
        this.selectedClasses = []
        this.searchByFullName = ''
        this.isVerified = null
        this.hasErrors = null
      },
      async handleIsVerified(val, item) {
        try {
          this.isLoading = true
          await axios.patch(`paricipants/validate/${item.user.id}`, {
            isVerified: val
          })
          item.is_verified = val
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Возникла ошибка',
            showConfirmButton: false,
            timer: 1000
          })
        } finally {
          this.isLoading = false
        }
      },
      async handleHasErrors(val, item) {
        try {
          this.isLoading = true
          await axios.patch(`paricipants/validate/${item.user.id}`, {
            isIncorrectlyFilled: val
          })
          item.is_incorrectly_filled = val
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Возникла ошибка',
            showConfirmButton: false,
            timer: 1000
          })
        } finally {
          this.isLoading = false
        }
      },
      openDialog(item) {
        this.selectedParticipant = item
        this.$nextTick(() => {
          this.participantDialog = true
        })
      }
    }
  }
</script>
